<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12">
        <div style="background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(1,51,109,1) 20%, rgba(1,42,90,1) 80%, rgba(0,0,0,1) 100%); font-size: 1.3em" class="text-center white--text py-2 mb-2">
          {{ $t('home.msg') }}
        </div>
        <v-row no-gutters>
          <v-col cols="12" md="8">
            <v-carousel height="100%">
              <v-carousel-item
                src="/images/banner1.jpeg"
              ></v-carousel-item>
              <v-carousel-item
                src="/images/banner2.jpeg"
              ></v-carousel-item>
              <v-carousel-item
                src="/images/banner3.jpeg"
              ></v-carousel-item>
            </v-carousel>
          </v-col>
          <v-col cols="12" md="4">
            <div>
              <router-link to="/about">
                <img src="/images/boxes1.jpeg" alt="" width="100%">
              </router-link>

            </div>
            <div>
              <router-link to="/contact">
                <img src="/images/boxes2.jpeg" alt="" width="100%">
              </router-link>
            </div>
          </v-col>
          <v-col cols="12">
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
}
</script>
